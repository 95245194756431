"use client";

import React from "react";
import { TonConnectUIProvider } from "@tonconnect/ui-react";

export default function TonConnectProvider({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  const tonManifestUrl = `${process.env.NEXT_PUBLIC_ORIGIN_URL}/tonconnect-manifest-${process.env.NEXT_PUBLIC_APP_STAGE}.json`;
  return (
    <TonConnectUIProvider manifestUrl={tonManifestUrl}>
      {children}
    </TonConnectUIProvider>
  );
}
